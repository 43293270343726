<template>
  <div class="position-relative">
    <b-row>
      <b-col md="6">
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-toggle.sidebar-AddNewexpense>
          Add New Expense</b-button>
        <!-- </span> -->
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="8">
            <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group   label-cols-sm="4" label-align-sm="right" label-size="sm"
              label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-table
  v-if="suppliers.length > 0"
  :items="suppliers"
  :fields="fields"
  responsive="sm"
  :sticky-header="false"
  striped
  small
  id="supplier-table"
  :per-page="perPage"
  :current-page="currentPage"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
      <template #cell(time_created)="row">
        <span>{{ row.item.created_at | formatDate }}</span>
      </template>
      <template #cell(action)="row">
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item v-b-toggle.sidebar-edit-expense @click="sendexpenseEditProps(row.item)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteexpense(row.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <!-- </span> -->
      </template>
    </b-table>
    <div v-else class="text-center mt-4">{{ emptyExpenseMessage }}</div>
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
    <add-expense @refresh="getAllexpenses" />
    <edit-expense :editexpense="editexpense" @refresh="getAllexpenses" />
    <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>
<script>
import {
  BTable,
  BButton,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddExpense from "./AddExpence.vue";
import EditExpense from "./EditExpence.vue";
import User from '../../Helpers/User'
export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    ToastificationContent,
    AddExpense,
    EditExpense
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: "25",
      pageOptions: [25, 50, 100, 200],
      currentPage: 1,
      totalRows: 1,
      emptyExpenseMessage: "No Data Found.",
      filter: null,
      filterOn: [],
      fields: ["id", "date", "supplier", "category", "amount", "tax", "time_created", { key: "action", label: "Action", tdClass: 'actionClass' }],
      suppliers: [],
      loader: false,
      supplierData: {},
      editexpense: {}
    };
  },
  mounted() {
  },
  created() {
    this.getAllexpenses();
  },
  methods: {
    checkPermission(permission) {
      return User.checkPermission(permission)
    },
    sendexpenseEditProps(param) {
      // console.log('param', param)
      this.editexpense = param
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllexpenses() {
      this.loader = true;
      axios
        .get(process.env.VUE_APP_API + "/expense")
        .then((res) => {
          this.loader = false;
          this.suppliers = res["data"];
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    deleteexpense(expenseId) {
      if (expenseId) {
        this.loader = true
        axios({
          method: 'delete',
          url: process.env.VUE_APP_API + '/expense/expense',
          data: {
            'expenseId': expenseId,
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllexpenses()
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'supplier Deleted',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-left'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });

      }
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("hh:mm:ss A");
      }
    },
  },
}

</script>
    
<style>
.actionClass {
  max-width: 300px;
}
</style>